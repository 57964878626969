



























































import useSelectItems from "@/use/selectItems";
import {
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { v4 as uuid } from "uuid";

export default defineComponent({
  components: {
    MEditor: () => import("@/components/molecules/editor/m-editor.vue"),
  },

  setup(_, { root }) {
    const { shortLanguageItems } = useSelectItems({ root });

    const model = reactive<{
      languageSwitch: string;
      polishTemplate: any;
      polishContent: any;
      englishTemplate: any;
      englishContent: any;
      polishTemplateAdded: any;
      englishTemplateAdded: any;
    }>({
      languageSwitch: "pl",
      polishTemplate: "",
      polishContent: "",
      englishTemplate: "",
      englishContent: "",
      polishTemplateAdded: "",
      englishTemplateAdded: "",
    });

    const state = reactive({
      templates: [],
      dialog: false,
      success: false,
      error: false as boolean | number,
      loading: false,
      loaded: false,
      polishEditorLoaded: false,
      englishEditorLoaded: false,
    });

    const fetchTemplates = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get("summary-page-template")
        .then(({ data: { summaryPageTemplates } }) => {
          state.templates = summaryPageTemplates;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.templates = [];
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    onMounted(fetchTemplates);

    const fetchTemplateData = (templateId: string, type: string) => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`summary-page-template/${templateId}`, { params: { type: type } })
        .then(({ data }) => {
          if (type === "polish") {
            model.polishContent = data.content || undefined;
            state.polishEditorLoaded = true;
          } else {
            model.englishContent = data.content || undefined;
            state.englishEditorLoaded = true;
          }
        })
        .catch((error) => console.log(error))
        .finally(() => {
          state.loading = false;
        });
    };

    const fetchEventTemplate = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`event/${root.$route.params.id}/summary-page`, {
          params: { type: "polish" },
        })
        .then(({ data }) => {
          model.polishContent = data.content || undefined;
          model.polishTemplateAdded = data.content || undefined;
          state.polishEditorLoaded =
            data && model.languageSwitch === "pl" ? true : false;
          axiosInstance
            .get(`event/${root.$route.params.id}/summary-page`, {
              params: { type: "english" },
            })
            .then(({ data }) => {
              model.englishContent = data.content || undefined;
              model.englishTemplateAdded = data.content || undefined;
              state.englishEditorLoaded =
                data && model.languageSwitch === "en" ? true : false;
            })
            .catch((error) => {
              if (error.response.status === 404) {
                state.templates = [];
              } else {
                console.log(error);
              }
            });
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.templates = [];
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    onMounted(fetchEventTemplate);

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 400:
          return `${root.$tc("layout.errors.400")}`;
        case 401:
          return `${root.$tc("layout.errors.401")}`;
        case 404:
          return `${root.$tc("layout.errors.404")}`;
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return `${root.$tc("layout.errors.default")}`;
      }
    };

    watch(
      () => model.polishTemplate,
      () => fetchTemplateData(model.polishTemplate, "polish")
    );
    watch(
      () => model.englishTemplate,
      () => fetchTemplateData(model.englishTemplate, "english")
    );
    watch(
      () => model.polishTemplate,
      () => {
        if (state.polishEditorLoaded) {
          state.polishEditorLoaded = false;
          fetchTemplateData(model.polishTemplate, "polish");
        }
      }
    );
    watch(
      () => model.englishTemplate,
      () => {
        if (state.englishEditorLoaded) {
          state.englishEditorLoaded = false;
          fetchTemplateData(model.englishTemplate, "english");
        }
      }
    );
    watch(
      () => model.languageSwitch,
      () => fetchEventTemplate()
    );

    const onSubmit = async (type: string) => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      if (
        (model.languageSwitch === "pl" && model.polishTemplateAdded) ||
        (model.languageSwitch === "en" && model.englishTemplateAdded)
      ) {
        const data = {
          content:
            model.languageSwitch === "pl"
              ? model.polishContent || undefined
              : model.englishContent || undefined,
        };
        axiosInstance
          .put(`/event/${root.$route.params.id}/summary-page`, data, {
            params: { type: type },
            headers: {
              event: root.$route.params.id,
            },
          })
          .then(() => {
            state.success = true;
            state.error = false;
            root.$store.commit("snackbar/PUSH_MESSAGE", {
              id: uuid(),
              color: "success",
              message: root.$tc("layout.misc.templateEdited"),
            });
          })
          .catch((error) => {
            state.error = error.response.status;
            root.$store.commit("snackbar/PUSH_MESSAGE", {
              id: uuid(),
              color: "error",
              message: getErrorMessage(state.error as number),
            });
          })
          .finally(() => (state.loading = false));
      } else {
        const data = {
          content:
            model.languageSwitch === "pl"
              ? model.polishContent || undefined
              : model.englishContent || undefined,
          type: type,
        };
        axiosInstance
          .post(`/event/${root.$route.params.id}/summary-page`, data, {
            headers: {
              event: root.$route.params.id,
            },
          })
          .then(() => {
            state.success = true;
            state.error = false;
            root.$store.commit("snackbar/PUSH_MESSAGE", {
              id: uuid(),
              color: "success",
              message: root.$tc("layout.misc.templateSaved"),
            });
            fetchEventTemplate();
          })
          .catch((error) => {
            state.error = error.response.status;
            root.$store.commit("snackbar/PUSH_MESSAGE", {
              id: uuid(),
              color: "error",
              message: getErrorMessage(state.error as number),
            });
          })
          .finally(() => (state.loading = false));
      }
    };

    return { model, state, shortLanguageItems, onSubmit, getErrorMessage };
  },
});
